import React, { useState } from 'react';
import './style.css';

class Post {
  constructor(title, text) {
    this.title = title;
    this.bodytext = text;
  }
}

//why didn't I use a class for this? idk honestly, but function state is kind of cool
export default function App() {
  const [content, setContent] = useState(
    <PostContainer key={0} data={{ type: 'text', title: 'loading....' }} />
  );

  fetch('/text.json', {
    headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  })
    .then((response) => response.json())
    .then((data) => {
      let posts = data.posts.map((post) => (
        <PostContainer key={post.id} data={post} />
      ));
      setContent(posts);
    });

  //main screen component
  return (
    <div className="flex h-screen justify-center overflow-y-auto">
      <div className="md:text-justify text-left text-white w-1/2 flex flex-col items-center ">
        <h1 className="text-center lg:text-[4rem] sm:text-[3rem] text-[2.5rem] h-fit pt-8 ">
          Elilewis327.com
        </h1>
        <p className="text-md md:text-lg pb-8 ">
          Programmer & University Student
        </p>
        {content}
        <span className="text-sm mt-5">
          © 2022 Eli Lewis - Styled with Tailwind - Written in React - Developed
          using stackblitz
        </span>
      </div>
    </div>
  );
}

//post components
const PostContainer = (props) => {
  props = props.data; //allows passing differing amounts of arguments depending on the type, probably a better way to do this, maybe i'll figure it out sometime

  if (props.type == 'text') {
    return <TextPost data={props} />;
  } else if (props.type == 'list') {
    return <ListPost data={props} />;
  } else {
    return <div></div>;
  }
};

const TextPost = (props) => {
  props = props.data;
  return (
    <div className="p-8 m-2 md:m-4 lg:m-6 w-[85vw] break-normal xl:w-full border-solid border-2 border-none rounded-2xl bg-gradient-to-r from-slate-800 via-slate-700 to-slate-800">
      <h1 className="text-center text-xl md:text-2xl lg:text-4xl m-4">
        {props.title}
      </h1>
      <span className=" text-lg md:text-xl lg:text-2xl">{props.body}</span>
    </div>
  );
};

const ListPost = (props) => {
  props = props.data;

  let listElements = props.list.map((element) => {
    if (element.type == 'link') {
      return (
        <li className="m-2" key={element.id}>
          - <a href={element.text}> {element.text} </a>
        </li>
      );
    } else if (element.type == 'text') {
      return (
        <li className="m-2" key={element.id}>
          - {element.text}
        </li>
      );
    }
  });

  return (
    <div className="p-8 m-2 md:m-4 lg:m-6 w-[85vw] break-all xl:w-full border-solid border-2 border-none rounded-2xl bg-gradient-to-r from-slate-800 via-slate-700 to-slate-800">
      <h1 className="text-center text-xl md:text-2xl lg:text-4xl m-4">
        {props.title}
      </h1>
      <div className=" text-lg md:text-xl lg:text-2xl  mb-4">{props.body}</div>
      <ul className="text-lg md:text-xl lg:text-2xl ">{listElements}</ul>
    </div>
  );
};
